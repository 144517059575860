<template>
  <div class="app-container createArticle">
    <div class="editor">
      <p class="emphasize">
        提示: 如果上传的视频为空白，插入视频前用
        <span class="link" @click="linkHandler"> 格式工厂</span>
        转码为H.264编码的MP4格式后再上传; word文档请上传docx格式
      </p>
      <el-form ref="form" label-width="90px" :model="form" :rules="rules">
        <el-form-item label="文章标题:" prop="title">
          <el-input style="width: 240px" size="small" v-model="form.title"></el-input>
        </el-form-item>
        <!-- 文档编辑器Editor -->
        <el-form-item label="文章详情:" prop="content">
          <Editor
            id="basisEditor"
            :val.sync="form.content"
            placeholder="请输入"
            :height="600"
            serverUrl="serverUrl"
            useObjectStr="CONTENT_UEDITOR_FILE"
            :folderPath="'9'"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom">
      <el-button size="small" @click="goBack"> 取消 </el-button>
      <el-button :loading="loading" size="small" type="primary" @click="submit"> 发布 </el-button>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/editor'
import { helpCenterContentDetail, helpCenterContentUpdate, helpCenterContentCreate } from '@/api/helpcenter/helpcenter'
export default {
  components: {
    Editor
  },
  data() {
    return {
      loading: false,
      form: {
        title: '',
        content: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入文章标题', trigger: 'blur' },
          { min: 2, max: 64, message: '长度在 2 到 64 个字符', trigger: 'blur' }
        ],
        content: [{ required: true, message: '请输入文章详情', trigger: 'blur' }]
      },
      id: this.$route.query.id || '',
      categoryId: this.$route.query.categoryId
    }
  },
  async created() {
    if (this.id !== '') {
      try {
        const { code, detail } = await helpCenterContentDetail({ id: this.id })
        if ($SUC({ code })) {
          this.form.title = detail.title
          this.form.content = detail.content
        }
      } catch (e) {}
    }
  },
  methods: {
    linkHandler() {
      window.open('http://www.pcgeshi.com/index.html', '_blank')
    },
    goBack() {
      this.$router.replace({
        path: '/infoManage/articleManage',
        query: {
          id: this.$route.query.categoryId,
          categoryName: this.$route.query.categoryName
        }
      })
    },
    submit() {
      const textContent = this.form.content.replace(/<[^>]+>/gi, '').replace(/\n/gi, ' ') //去除html和\n换行标签
      // return console.log(textContent);
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let func = {}
          let data = {}
          if (this.id !== '') {
            func = helpCenterContentUpdate
            data = {
              categoryId: this.categoryId,
              content: this.form.content,
              textContent: textContent.length ? textContent : 'none',
              id: this.id,
              title: this.form.title
            }
          } else {
            func = helpCenterContentCreate
            data = {
              categoryId: this.categoryId,
              content: this.form.content,
              textContent,
              title: this.form.title
            }
          }
          try {
            this.loading = true
            const { code } = await func(data)
            if ($SUC({ code })) {
              this.$message.success(this.id === '' ? '创建文章成功' : '编辑文章成功')
              this.goBack()
            } else {
              this.$message.error(this.id === '' ? '创建文章失败' : '编辑文章失败')
            }
          } catch (e) {
            this.$message.error(this.id === '' ? '创建文章失败' : '编辑文章失败')
          } finally {
            this.loading = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.createArticle {
  position: relative;
  padding: 20px 0px;
  .emphasize {
    color: red;
    margin-left: 40px;
    .link {
      color: $color-primary;
      cursor: pointer;
    }
  }

  .editor {
    padding: 0 346px 0 15px;
  }
  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 10px 1px #999;
  }
}
</style>
